import React, { useEffect } from 'react'
import PageTitle from '../../Layout/PageTitle'
import PageContainer from '../../Layout/PageContainer'
import BSEImg from '../../../img/blog-img/bse/BSEScreenshot.png'
import BSEFlow from '../../../img/blog-img/bse/bseflow.png'
import BlogImage from '../../Layout/BlogImage'
import CaptionedImage from '../../Layout/CaptionedImage'
import PageSubtitle from '../../Layout/PageSubtitle'
import Content from '../../Layout/Content'

import Prism from 'prismjs'
import 'prismjs/components/prism-java';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'

import styled from 'styled-components'


const springCode = `
// ApplicationController.java

@Controller
public class ApplicationController {
    
    @GetMapping(value = "/{path:[^\\.]*}")
    public String serveApplication() {
        return "index.html";
    }
}

`.trim()

const viteConfig = `
    import { defineConfig } from 'vite'
    import react from '@vitejs/plugin-react'


    // https://vitejs.dev/config/
    export default defineConfig({
    plugins: [react()],
    root: '',
    build: {
        outDir: '../src/main/resources/static', // ensure this path points to your Spring project's "resource/static" folder
        emptyOutDir: true
    }
    })
`

const CodeContainer = styled.pre`
    text-align: left;

    & .token-line {
        line-height: 1.3em;
        height: 1.3em;
    }
`

const TerminalContainer = styled.code`
    background: black;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 5px;
`

export default function BuildsheetEditorBlog() {
    useEffect(() => {
        setTimeout(() => Prism.highlightAll(), 0)
      }, []);
    return (
        <Content>

                <PageTitle>Serving a React App with Spring Framework</PageTitle>
                <div>
                    <PageSubtitle>Summary</PageSubtitle>

                    <ol>
                        <li>Compile and copy your React build output</li>
                        <li>Add a Spring controller to handle serving the app and sub-routes</li>
                    </ol>
            
                    <PageSubtitle>Compiling Your React App</PageSubtitle>
                    <p>
                        Run your bundler's build command, such as <TerminalContainer>npm run build</TerminalContainer> to compile your React app for production. Set up a build destination or copy all compiled React files to your Spring project under <TerminalContainer>/src/main/resources/static</TerminalContainer>.
                    </p>

                    <p>Sample configuration for Vite:</p>

                    <pre className="line-numbers">
                        <code className="language-javascript">{viteConfig}</code>
                    </pre>

                    <PageSubtitle>Serving your React app from Spring</PageSubtitle>
                    <p>
                        You can serve a React App with routing using the below snippet:
                    </p>

                    <pre className="line-numbers">
                        <code className="language-java">{springCode}</code>
                    </pre>

                    <p>
                        This ensures that navigating to <TerminalContainer>https://website.com/</TerminalContainer> or <TerminalContainer>https://website.com/route</TerminalContainer> returns the compiled front-end, and ensures that any sub-routes are also forwarded properly. Additionally, extra routes outside of "/", such as API routes, will be handled.
                    </p>
                    
                </div>

        </Content>
    )
}
