import React, { useState } from 'react'
import styled from 'styled-components'
import { BORDER_RADIUS } from './BorderRadius';
import { SHADOWS } from './Shadows';

const ToggleContainer = styled.div`
        font-weight: bold;
        align-items: center;
        justify-content: center;
        background: linear-gradient(-45deg, ${props => props.itemColor}11 0%, ${props => props.itemColor}11 66%, white 100%);
        aspect-ratio: 1;
        box-sizing: border-box;
        transition: all .2s ease-out;
        
        border-radius: ${BORDER_RADIUS};
        border: 1px solid ${props => props.itemColor}10;
       // box-shadow:
          50 1px 1px ${props => props.itemColor}08,
          0 2px 2px ${props => props.itemColor}08,
          0 4px 4px ${props => props.itemColor}08,
          0 8px 8px ${props => props.itemColor}08,
          0 16px 16px ${props => props.itemColor}08;
        
        text-align: center;
        padding: 20px;


        &:hover {
          //border: 1px solid ${props => props.itemColor};

          box-shadow:
          0 2px 2px ${props => props.itemColor}08,
          0 4px 4px ${props => props.itemColor}08,
          0 8px 8px ${props => props.itemColor}08,
          0 16px 16px ${props => props.itemColor}08,
          0 32px 32px ${props => props.itemColor}08;
        }

        & img {
            height: 40%;
            width: 40%;
        }
    `

    const ToggleContainerInner = styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
        justfify-content: center;
        align-items: center;
    `
    

export default function ToggleChip(props) {

  return (

    <ToggleContainer itemColor={props.item.BaseColor}>
      <ToggleContainerInner>
        {props.item.IconComponentResponsive} {props.item.Text}
      </ToggleContainerInner>
        
    </ToggleContainer>
  )
}
