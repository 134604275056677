import React from "react";
import styled from "styled-components";

import TerminalCard from "../Terminal/TerminalCard";
import DownloadIcon from "../../img/icons/download.svg";
import ActionButton from "../Layout/Redesign/ActionButton";

const AboutBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export default function AboutBlurb() {
  return (
    <AboutBlock>
      <TerminalCard>
        <p>
          With over four years of software development & IT industry experience, I greatly enjoy problem-solving and
          engineering solutions that help others succeed.
        </p>
      </TerminalCard>
      <p>
          <a href="Matt Caloger Resume.pdf" download className="mobile-only">
                    <ActionButton icon={DownloadIcon} text="Download Resume" />
                  </a>
      </p>

      
    </AboutBlock>
  );
}
