import * as LanguageIcons from '../../Terminal/LanguageIcons'
import ResponsiveIconImage from "./ResponsiveIconImage";
import IconImage from "../IconImage";
import * as LanguageColors from "../../Terminal/Languages";

export const JavaData = {
    Name: 'Java',
    Icon: LanguageIcons.JavaIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.JavaIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.JavaIcon}></IconImage>,
    Color: <LanguageColors.ColorJava />,
    Tag: <LanguageColors.ColorJavaTag />,
    Text: <LanguageColors.ColorJava>Java</LanguageColors.ColorJava>,
    BaseColor: LanguageColors.BaseColor.java
}

export const CssData = {
    Name: 'CSS',
    Icon: LanguageIcons.CssIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.CssIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.CssIcon}></IconImage>,
    Color: <LanguageColors.ColorCss />,
    Tag: <LanguageColors.ColorCssTag />,
    Text: <LanguageColors.ColorCss>CSS</LanguageColors.ColorCss>,
    BaseColor: LanguageColors.BaseColor.css
}

export const HtmlData = {
    Name: 'HTML',
    Icon: LanguageIcons.HtmlIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.HtmlIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.HtmlIcon}></IconImage>,
    Color: <LanguageColors.ColorHtml />,
    Tag: <LanguageColors.ColorHtmlTag />,
    Text: <LanguageColors.ColorHtml>HTML</LanguageColors.ColorHtml>,
    BaseColor: LanguageColors.BaseColor.html
}

export const JavaScriptData = {
    Name: 'JavaScript',
    Icon: LanguageIcons.JavaScriptIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.JavaScriptIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.JavaScriptIcon}></IconImage>,
    Color: <LanguageColors.ColorJs />,
    Tag: <LanguageColors.ColorJsTag />,
    Text: <LanguageColors.ColorJs>JavaScript</LanguageColors.ColorJs>,
    BaseColor: LanguageColors.BaseColor.javaScript
}

export const TypeScriptData = {
    Name: 'TypeScript',
    Icon: LanguageIcons.TypeScriptIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.TypeScriptIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.TypeScriptIcon}></IconImage>,
    Color: <LanguageColors.ColorTypescript />,
    Tag: <LanguageColors.ColorTypescriptTag />,
    Text: <LanguageColors.ColorTypescript>TypeScript</LanguageColors.ColorTypescript>,
    BaseColor: LanguageColors.BaseColor.typescript 
}

export const CSharpData = {
    Name: 'C#',
    Icon: LanguageIcons.CSharpIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.CSharpIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.CSharpIcon}></IconImage>,
    Color: <LanguageColors.ColorCSharp />,
    Tag: <LanguageColors.ColorCSharpTag />,
    Text: <LanguageColors.ColorCSharp>C#</LanguageColors.ColorCSharp>,
    BaseColor: LanguageColors.BaseColor.csharp
}

export const SpringData = {
    Name: 'Spring',
    Icon: LanguageIcons.SpringIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.SpringIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.SpringIcon}></IconImage>,
    Color: <LanguageColors.ColorSpring />,
    Tag: <LanguageColors.ColorSpringTag />,
    Text: <LanguageColors.ColorSpring>Spring Framework</LanguageColors.ColorSpring>,
    BaseColor: LanguageColors.BaseColor.spring
}

export const ReactData = {
    Name: 'React',
    Icon: LanguageIcons.ReactIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.ReactIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.ReactIcon}></IconImage>,
    Color: <LanguageColors.ColorReactJs />,
    Tag: <LanguageColors.ColorReactJsTag />,
    Text: <LanguageColors.ColorReactJs>React</LanguageColors.ColorReactJs>,
    BaseColor: LanguageColors.BaseColor.react
}

export const AngularData = {
    Name: 'Angular',
    Icon: LanguageIcons.AngularIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.AngularIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.AngularIcon}></IconImage>,
    Color: <LanguageColors.ColorAngular />,
    Tag: <LanguageColors.ColorAngularTag />,
    Text: <LanguageColors.ColorAngular>Angular</LanguageColors.ColorAngular>,
    BaseColor: LanguageColors.BaseColor.angular
}

export const IntelliJData = {
    Name: 'IntelliJ',
    Icon: LanguageIcons.IntelliJIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.IntelliJIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.IntelliJIcon}></IconImage>,
    Color: <LanguageColors.ColorIntellij />,
    Tag: <LanguageColors.ColorIntelliJTag />,
    Text: <LanguageColors.ColorIntellij>IntelliJ</LanguageColors.ColorIntellij>,
    BaseColor: LanguageColors.BaseColor.intellij
}

export const VisualStudioData = {
    Name: 'Visual Studio',
    Icon: LanguageIcons.VisualStudioIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.VisualStudioIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.VisualStudioIcon}></IconImage>,
    Color: <LanguageColors.ColorVisualStudio />,
    Tag: <LanguageColors.ColorVisualStudioTag />,
    Text: <LanguageColors.ColorVisualStudio>Visual Studio</LanguageColors.ColorVisualStudio>,
    BaseColor: LanguageColors.BaseColor.visualstudio
}

export const GitData = {
    Name: 'Git',
    Icon: LanguageIcons.GitIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.GitIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.GitIcon}></IconImage>,
    Color: <LanguageColors.ColorGit />,
    Tag: <LanguageColors.ColorGitTag />,
    Text: <LanguageColors.ColorGit>Git</LanguageColors.ColorGit>,
    BaseColor: LanguageColors.BaseColor.git
}

export const AspNetData = {
    Name: 'ASP.NET',
    Icon: LanguageIcons.DotnetIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.DotnetIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.DotnetIcon}></IconImage>,
    Color: <LanguageColors.ColorDotnet />,
    Tag: <LanguageColors.ColorDotnetTag />,
    Text: <LanguageColors.ColorDotnet>ASP.NET</LanguageColors.ColorDotnet>,
    BaseColor: LanguageColors.BaseColor.aspdotnet

}

export const NodeData = {
    Name: 'Node',
    Icon: LanguageIcons.NodeIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.NodeIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.NodeIcon}></IconImage>,
    Color: <LanguageColors.ColorNode />,
    Tag: <LanguageColors.ColorNodeTag />,
    Text: <LanguageColors.ColorNode>Node</LanguageColors.ColorNode>,
    BaseColor: LanguageColors.BaseColor.node

}

export const SQLData = {
    Name: 'SQL',
    Icon: LanguageIcons.SqlIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.SqlIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.SqlIcon}></IconImage>,
    Color: <LanguageColors.ColorSql />,
    Tag: <LanguageColors.ColorSqlTag />,
    Text: <LanguageColors.ColorSql>SQL</LanguageColors.ColorSql>,
    BaseColor: LanguageColors.BaseColor.sql

}

export const VisualStudioCodeData = {
    Name: 'Visual Studio Code',
    Icon: LanguageIcons.VisualStudioCodeIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.VisualStudioCodeIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.VisualStudioCodeIcon}></IconImage>,
    Color: <LanguageColors.ColorVisualStudioCode />,
    Tag: <LanguageColors.ColorVisualStudioCodeTag />,
    Text: <LanguageColors.ColorVisualStudioCode>Visual Studio Code</LanguageColors.ColorVisualStudioCode>,
    BaseColor: LanguageColors.BaseColor.visualstudiocode

}

const FigmaData = {
    Name: 'Figma',
    Icon: LanguageIcons.FigmaIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.FigmaIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.FigmaIcon}></IconImage>,
    Color: <LanguageColors.ColorFigma />,
    Tag: <LanguageColors.ColorFigmaTag />,
    Text: <LanguageColors.ColorFigma>Figma</LanguageColors.ColorFigma>,
    BaseColor: LanguageColors.BaseColor.figma
}

const DockerData = {
    Name: 'Docker',
    Icon: LanguageIcons.DockerIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.DockerIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.DockerIcon}></IconImage>,
    Color: <LanguageColors.ColorDocker />,
    Tag: <LanguageColors.ColorDockerTag />,
    Text: <LanguageColors.ColorDocker>Docker</LanguageColors.ColorDocker>,
    BaseColor: LanguageColors.BaseColor.docker
}

const ApacheData = {
    Name: 'Apache',
    Icon: LanguageIcons.ApacheIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.ApacheIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.ApacheIcon}></IconImage>,
    Color: <LanguageColors.ColorApache />,
    Tag: <LanguageColors.ColorApacheTag />,
    Text: <LanguageColors.ColorApache>Apache</LanguageColors.ColorApache>,
    BaseColor: LanguageColors.BaseColor.apache

}

const PerlData = {
    Name: 'Perl',
    Icon: LanguageIcons.PerlIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.PerlIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.PerlIcon}></IconImage>,
    Color: <LanguageColors.ColorPerl />,
    Tag: <LanguageColors.ColorPerlTag />,
    Text: <LanguageColors.ColorPerl>Perl</LanguageColors.ColorPerl>,
    BaseColor: LanguageColors.BaseColor.perl

}

const SalesforceData = {
    Name: 'Salesforce',
    Icon: LanguageIcons.SalesforceIcon,
    IconComponentResponsive: <ResponsiveIconImage src={LanguageIcons.SalesforceIcon}></ResponsiveIconImage>,
    IconComponentFixed: <IconImage src={LanguageIcons.SalesforceIcon}></IconImage>,
    Color: <LanguageColors.ColorSalesforce />,
    Tag: <LanguageColors.ColorSalesforceTag />,
    Text: <LanguageColors.ColorSalesforce>Salesforce</LanguageColors.ColorSalesforce>,
    BaseColor: LanguageColors.BaseColor.salesforce

}


export const LanguageList = [
    JavaData,
    SpringData,
    CSharpData,
    AspNetData,
    TypeScriptData,
    ReactData,
    AngularData,
    JavaScriptData,
    NodeData,
    PerlData,
    SalesforceData,
    HtmlData,
    CssData,
    VisualStudioData,
    IntelliJData,
    GitData,
    SQLData,
    FigmaData,
    DockerData,
    ApacheData,
    
    // 'PHP',
    // 'React',
    // 'Node',
    
    
    
    // 'SQL',
    // 'AspNet',
    // 'Git',
    // 'IntelliJ',
    // 'VisualStudio',
    // 'VisualStudioCode',
    // 'Angular',
    // 'Figma'
]

