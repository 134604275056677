import styled from "styled-components";

const ResponsiveIconImage = styled.img`

    width: 100%;
    height: 100%;
    aspect-ratio: 1;

`

export default ResponsiveIconImage