import styled from "styled-components"


export const LangSpan = styled.div`
color: black; 
font-size: 1em; user-select: none; cursor: default;`

export const BaseColor = {
    java: "#E42D2F",
    spring: "#5EB241",
    typescript: "#2F72BC",
    react:"#77D4F2",
    angular: "#C3002F",
    // javaScript: "#f7df1e",
    javaScript: "#999999",
    node: "#3B7F3A",
    html: "#e54c21",
    css: "#2465f1",
    csharp: "#9366CB",
    aspdotnet: "#9E89E7",
    git: "#F05133",
    sql: "#F1AA00",
    perl: "#009cc7",
    figma: "#999999",
    docker: "#2497ED",
    apache: "#B6204B",
    intellij: "#000000",
    visualstudiocode: "#1A8CD3",
    visualstudio: "#9356CF",
    salesforce: "#019cdc"
}

export const LangTag = styled(LangSpan)`padding: 5px; border-radius: 5px; user-select: none; cursor: default;`
export const ColorJs = styled(LangSpan)`color: black;`
export const baseJSColor = ""
export const ColorJsTag = styled(LangTag)`background: black; color: black; `
export const ColorHtml = styled(LangSpan)`color: #e54c21;`
export const ColorHtmlTag = styled(LangTag)`background: #e54c21; color: white;`
export const ColorCss = styled(LangSpan)`color: #2465f1;`
export const ColorCssTag = styled(LangTag)`background: #2465f1; color: White;`
export const ColorPhp = styled(LangSpan)`color: #787CB4;`
export const ColorPhpTag = styled(LangTag)`background: #787CB4; color: White;`
export const ColorCSharp = styled(LangSpan)`color: #9366CB;`
export const ColorJava = styled(LangSpan)`color: #E42D2F;`
export const ColorSpring = styled(LangSpan)`color: #5EB241;`
export const ColorNode = styled(LangSpan)`color: #3B7F3A;`
export const ColorPhotoshop = styled(LangSpan)`color: #3A9FF2;`
export const ColorIllustrator = styled(LangSpan)`color: #F29202;`
export const ColorTypescript = styled(LangSpan)`color: #2F72BC;`
export const ColorReactJs = styled(LangSpan)`color: #77D4F2;`
export const ColorSql = styled(LangSpan)`color: #F1AA00;`
export const ColorDotnet = styled(LangSpan)`color: #9E89E7;`
export const ColorGit = styled(LangSpan)`color: #F05133;`
export const ColorIntellij = styled(LangSpan)`color: #000000;`
export const ColorVisualStudio = styled(LangSpan)`color: #9356CF;`
export const ColorVisualStudioCode = styled(LangSpan)`color: #1A8CD3;`
export const ColorCSharpTag = styled(LangTag)`background: #9366CB; color: White;`;
export const ColorJavaTag = styled(LangTag)`background: #E42D2F; color: White;`;
export const ColorSpringTag = styled(LangTag)`background: #5EB241; color: White;`;
export const ColorNodeTag = styled(LangTag)`background: #3B7F3A; color: White;`;
export const ColorPhotoshopTag = styled(LangTag)`background: #3A9FF2; color: White;`;
export const ColorIllustratorTag = styled(LangTag)`background: #F29202; color: black;`;
export const ColorTypescriptTag = styled(LangTag)`background: #2F72BC; color: White;`;
export const ColorReactJsTag = styled(LangTag)`background: #77D4F2; color: black;`;
export const ColorSqlTag = styled(LangTag)`background: #F1AA00; color: black;`;
export const ColorDotnetTag = styled(LangTag)`background: #9E89E7; color: white;`
export const ColorGitTag = styled(LangTag)`background: #9E89E7; color: white;`
export const ColorIntelliJTag = styled(LangTag)`background: #000000; color: white;`
export const ColorVisualStudioTag = styled(LangTag)`background: #9356CF; color: white;`
export const ColorVisualStudioCodeTag = styled(LangTag)`background: #1A8CD3; color: white;`
export const ColorAngular = styled(LangSpan)`color: #C3002F;`
export const ColorAngularTag = styled(LangTag)`background: #C3002F; color: white;`
export const ColorFigma = styled(LangSpan)`color: black;`
export const ColorFigmaTag = styled(LangTag)`background: black; color: white;`
export const ColorDocker = styled(LangSpan)`color: #2497ED;`
export const ColorDockerTag = styled(LangTag)`background: #2497ED; color: white;`
export const ColorApache = styled(LangSpan)`color: #B6204B;`
export const ColorApacheTag = styled(LangTag)`background: #B6204B; color: white;`
export const ColorPerl = styled(LangSpan)`color: #009cc7;`
export const ColorPerlTag = styled(LangTag)`background: #B6204B; color: white;`
export const ColorSalesforce = styled(LangSpan)`color: #019cdc;`
export const ColorSalesforceTag= styled(LangTag)`background: #019cdc; color: white;`