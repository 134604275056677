import React from "react";
import * as LanguageIcon from "../../Terminal/LanguageIcons";
import ProjectCard from "./ProjectCard";
import IconImage from "../../Layout/IconImage";

const projects = [
  {
    url: "stasher",
    title: "Stasher",
    summary: "A secure message sharing service.",
    tags: ["Security", "Encryption"],
    icons: [
      <IconImage src={LanguageIcon.JavaIcon} />,
      <IconImage src={LanguageIcon.SpringIcon} />,
      <IconImage src={LanguageIcon.TypeScriptIcon} />,
      <IconImage src={LanguageIcon.ReactIcon} />,
      <IconImage src={LanguageIcon.SqlIcon} />
    ],
    github: "https://github.com/mattcaloger/Stasher",
  },
  {
    url: "Budgie",
    title: "Budgie",
    summary:
      "An expense tracking tool with data visualization",
    tags: ["Finance", "Data Visualization"],
    icons: [
      <IconImage src={LanguageIcon.JavaIcon} />,
      <IconImage src={LanguageIcon.SpringIcon} />,
      <IconImage src={LanguageIcon.JavaScriptIcon} />,
      <IconImage src={LanguageIcon.ReactIcon} />,
      <IconImage src={LanguageIcon.SqlIcon} />
    ],
    github: "https://github.com/mattcaloger/Budgie"
  },
  {
    url: "shortcake",
    title: "Shortcake",
    summary: "A simple API for shortening and sharing URLs.",
    tags: ["URL Shortening"],
    icons: [
      <IconImage src={LanguageIcon.JavaScriptIcon} />,
      <IconImage src={LanguageIcon.NodeIcon} />,
      <IconImage src={LanguageIcon.AngularIcon} />,
      <IconImage src={LanguageIcon.SqlIcon} />
    ],
    github: "https://github.com/mattcaloger/Shortcake",
  },
  {
    url: "BookDB",
    title: "BookDB",
    summary: "Categorize books with uploadable covers.",
    tags: ["File Management"],
    icons: [
      <IconImage src={LanguageIcon.CSharpIcon} />,
      <IconImage src={LanguageIcon.DotnetIcon} />,
      <IconImage src={LanguageIcon.AngularIcon} />
    ],
    github: "https://github.com/mattcaloger/BookDB",
  }
];

export default function ProjectList() {
  return (
    <>
      {projects.map((project, index) => {
        return <ProjectCard key={index} {...project} />;
      })}
    </>
  );
}
